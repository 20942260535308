<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="holidayCalendarData === undefined">
      <h4 class="alert-heading">Error fetching Holiday Calendar data</h4>
    </b-alert>
    <app-collapse hover class="mb-1">
      <app-collapse-item title="Filters">
        <holiday-list-filters
          :filter-by-type.sync="filterByType"
          :type-option="typeOption"
          :filter-by-shift.sync="filterByShift"
          :shift-option="shiftOption"
          :filter-by-year.sync="filterByYear"

        />
      </app-collapse-item>
    </app-collapse>
    <!-- First Row -->
    <b-row v-if="holidayCalendarData">
      <b-col sm="6" md="4" v-for="(month, index) in months" :key="index">
        <template>
          <b-card class="holday-card">
            <b-badge variant="primary" class="badge">
              {{ month }} {{ filterByYear }}
            </b-badge>
            <app-collapse v-if="holidayCalendarData[month]">
              <app-collapse-item
                v-for="holiday in holidayCalendarData[month]"
                :key="holiday.hashid"
                v-bind:title="holiday.holiday_name + '(' + holiday.day + ')'"
              >
                <p>
                  <span v-for="shift in holiday.shifts" :key="shift.id">
                    {{ shift.name }},
                  </span>
                  - {{ holiday.holiday_type }}
                </p>
              </app-collapse-item>
            </app-collapse>
          </b-card>
        </template>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { ref, watch, onUnmounted } from "@vue/composition-api";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BTabs,
  BTab,
  BCard,
  BCardText,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueGoodTable } from "vue-good-table";
import holidayStoreModule from "../holidayStoreModule";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import HolidayListFilters from "@/views/holidays/list/HolidayListFilters.vue";

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BRow,
    BCol,
    BAlert,
    BLink,
    BTabs,
    BTab,
    BCard,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    VueGoodTable,
    StatisticCardWithAreaChart,
    HolidayListFilters,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  setup() {
    const holidayCalendarData = ref(null);
    const USER_APP_STORE_MODULE_NAME = "app-holidays";

    const typeOption = ref([]);
    const shiftOption = ref([]);
    const filterByType = ref(null);
    const filterByShift = ref(null);
    const filterByYear =ref(new Date().getFullYear());

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, holidayStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    watch(filterByType, (val) => {
      store
        .dispatch("app-holidays/fetchHolidayCalendar", {
          year: filterByYear.value,
          holiday_type: filterByType.value,
          shift: filterByShift.value,
        })
        .then((response) => {
          holidayCalendarData.value = response.data.data;
          typeOption.value = response.data.typeOption;
          shiftOption.value = response.data.shiftOption;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            holidayCalendarData.value = undefined;
          }
        });
    });
    watch(filterByShift, (val) => {
      store
        .dispatch("app-holidays/fetchHolidayCalendar", {
          year: filterByYear.value,
          holiday_type: filterByType.value,
          shift: filterByShift.value,
        })
        .then((response) => {
          holidayCalendarData.value = response.data.data;
          typeOption.value = response.data.typeOption;
          shiftOption.value = response.data.shiftOption;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            holidayCalendarData.value = undefined;
          }
        });
    });
    watch(filterByYear, (val) => {
      store
        .dispatch("app-holidays/fetchHolidayCalendar", {
          year: filterByYear.value,
          holiday_type: filterByType.value,
          shift: filterByShift.value,
        })
        .then((response) => {
          holidayCalendarData.value = response.data.data;
          typeOption.value = response.data.typeOption;
          shiftOption.value = response.data.shiftOption;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            holidayCalendarData.value = undefined;
          }
        });
    });

    if ($cookies.get("userData")) {
      filterByShift.value = $cookies.get("userData").shift_details.id;
    }

    store
      .dispatch("app-holidays/fetchHolidayCalendar", {
        year: filterByYear.value,
        holiday_type: filterByType.value,
        shift: filterByShift.value,
      })
      .then((response) => {
        holidayCalendarData.value = response.data.data;
        typeOption.value = response.data.typeOption;
        shiftOption.value = response.data.shiftOption;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          holidayCalendarData.value = undefined;
        }
      });

    return {
      holidayCalendarData,
      typeOption,
      shiftOption,
      filterByType,
      filterByShift,
      filterByYear
    };
  },
  data() {
    return {
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    };
  },
  created() {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

<style lang="scss" scoped>
.add-btn {
  border: solid 1px;
  width: 70%;
}
.interview-pass {
  color: green;
}
.interview-fail {
  color: indianred;
}
.holday-card {
  height: calc(100% - 2rem);
}
</style>